import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { GeneratedSummaryModalComponent } from 'components/generate-text'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { NextNewsletterSendComponent } from 'components/schedule-editor/nextNewsletter'
import * as Validation from 'components/validation/validations'
import { RasaContext } from 'context'
import { differenceInSeconds } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { Button, Input } from 'reactstrap'
import { isBeforeCutoff, isInCutoffWindow } from 'shared/data-layer/issues'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_TIMEZONE, IMAGE, NORMAL_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import { AddArticlePreview } from '../add_article_preview'
import { AddEditArticleModal } from '../addEditArticleModal'
import { ArticleType } from '../constants'
import {
  AddArticleState,
  initialAddArticleState,
  Post,
} from '../edit-article/types'
import { SourceSection } from '../types'
import { ArticleAlertModal } from './modals/articleAlert'
import { ScheduleArticleModal } from './modals/scheduleArticle'
import { SectionSelectModal } from './modals/sectionSelect'
import { ArticleAction } from './types'

type AddArticleProps = GenericRedux.AllComponentPropsWithModal<Post>
export class ContentPoolAddArticleComponent extends RasaReactComponent<AddArticleProps, AddArticleState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private _interval: any
  private communityId: string = null

  constructor(p: AddArticleProps) {
    super(p, 'rasaCommunityArticle', initialAddArticleState)
    this.uploadImage = this.uploadImage.bind(this)
    this.clearImage = this.clearImage.bind(this)
    this.updateImageFromUrl = this.updateImageFromUrl.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.context.history$.subscribe((e) => {
      if (e) {
        this.props.propertyChanged('title', null)
      }
    })

    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.params),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, params, person]) => {
      this.communityId = activeCommunity.communityId
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.selectCommunity(activeCommunity.communityId)
      this.getSections(activeCommunity.communityId)
      this.setState({
        hasScheduledContentAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
        nextIssue: activeCommunity.nextIssue,
        selectedCommunity: activeCommunity.communityId,
        timezone: activeCommunity.data && activeCommunity.data.company_time_zone
          ? activeCommunity.data.company_time_zone : DEFAULT_TIMEZONE,
        type: params.type,
      }, () => this.setIntervals())
    })
  }

  public componentWillUnmount() {
    clearInterval(this._interval)
  }

  public render() {
    return this.state.isLoading ? this.renderLoader() : this.renderAdd()
  }

  protected renderLoader() {
    return (
    <div className="add-article-page">
      <div className="bouncing-balls">
        <Loading size="64"/>
      </div>
    </div>
  )}

  protected renderAdd() {
    return (
    <div className="add-article">
      <div className="header-container">
        <HeaderComponent
          icon={'addArticle'}
          title={'ARTICLES'}
          subTitle={ this.state.type && ArticleType[this.state.type]
            ? `Add a New ${ArticleType[this.state.type].displayName} Article` : 'Add a New Article'}
          description={[
            'Manually add articles to your Upcoming pool here.',
          ]}
        />
        {this.state.nextIssue && this.state.nextIssue.schedule.start_date &&
        <div className="next-nl-wrapper">
          <NextNewsletterSendComponent
            timezone={null}
            nextIssue={this.state.nextIssue}>
            {isInCutoffWindow(this.state.nextIssue, this.state.currentTime) &&
            <div>
              <em>Any articles added now will be automatically boosted to ensure they go in your newsletter.</em>
            </div>}
          </NextNewsletterSendComponent>
        </div>}
      </div>
      <h6>Article URL</h6>
      <div className="article-url-wrapper">
        <div className="modal-wrapper">
          <ArticleAlertModal data={this.props.modals} closeModal={this.props.closeModal} title="Alert"/>
          <ScheduleArticleModal
            data={this.props.modals}
            closeModal={this.props.closeModal}
            redirect={this.redirectToUpcomingArticles}
            saveModal={this.props.saveModal}
            updateModal={this.props.updateModal}
            title="Schedule Article"/>
        </div>
        <div className="article-url">
          <span>URL</span>
          <input value={this.props.data.url}
                onKeyDown={(e: any) => this.handleOnEnter(e)}
                onChange={(e: any) => this.urlChanged(e.target.value)}/>
        </div>
        <div>
          <Button className="reload-button"
                    value="Verify"
                    disabled={!this.validUrl() || this.state.isFetching}
                    onClick={() => this.fetchArticle()}>FETCH</Button>
          {this.state.isFetching &&
            <div className="bouncing-balls">
              <Loading size="64"/>
            </div>}
        </div>
      </div>
      { this.state.isFetched &&
        <div className="new-article-mode">
          <div id="details" >
            <h6>Title</h6>
            <div className="article-field">
              <Input value={this.props.data.title}
                     placeholder="Please enter a title - required to add an article"
                     onChange={(e) => this.propertyChanged('title', e.target.value)}/>
            </div>
            <h6>Source</h6>
            <div className="article-field">
              <Input value={this.props.data.site_name}
                     onChange={(e) => this.propertyChanged('site_name', e.target.value)}/>
            </div>
            <h6>Excerpt</h6>
            <div className="article-field">
              <Input type="textarea"
                     value={this.props.data.description}
                     onChange={(e) => this.propertyChanged('description', e.target.value)}/>
            </div>
            <h6>Article Preview:</h6>
            <AddArticlePreview isUploading={this.state.isUploading}
              imageUrl={this.props.data.image_url}
              updateImageUrl={this.updateImageFromUrl}
              uploadImage={this.uploadImage} clearImage={this.clearImage}
              data={this.props.data} description={this.articleDescription()}
              imageHeight={this.props.data.image_height} imageWidth={this.props.data.image_width} />
              {!isEmpty(this.state.sections) &&
              <div className="sections-select-container">
                <h6>Sections</h6>
                <DropdownComponent
                  data={this.state.sections}
                  selected={this.state.selectedSection ? this.state.selectedSection.key : null}
                  onChange={this.onDropdownChange}/>
              </div>}
                {this.state.isSaving ?
                <div>
                  <Loading size="64"/>
                </div>
                :
                <div className="buttons-container">
                  <Button value="Add Article"
                          disabled={!this.validArticle()}
                          onClick={() => this.saveArticle()}>
                      Add Article
                  </Button>
                  {false && this.state.hasScheduledContentAccess &&
                  (!ArticleType[this.state.type] || ArticleType[this.state.type].name !== ArticleType.reserved.name) &&
                  <Button value="Schedule Article"
                          disabled={!this.validArticle()}
                          onClick={() => this.props.openModal(ScheduleArticleModal.key,
                          {...this.props.data,
                          communityId: this.state.communityId,
                          timezone: this.state.timezone})}>
                      Schedule Article
                  </Button>}
                </div>
                }
            </div>
            {this.state.isSaving && !this.props.data.title &&
            <div>
              <Loading size="64"/>
            </div>}
        </div>}
    </div>
    )
  }

  protected clearImage() {
    this.propertiesChanged({
      blacklist_image_url: this.props.data.image_url, // should we blacklist on add as well?
      hosted_image_url: '',
      image_height: null,
      image_url: '',
      image_width: null,
    })
  }

  protected updateImageFromUrl(imageUrl) {
    this.propertiesChanged({
      image_url: imageUrl,
    })
  }

  protected uploadImage(image: any) {
    this.setState({
      isUploading: true,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: any) => {
        this.setState({
          error: true,
          isUploading: false,
        })
        this.propertiesChanged({
          hosted_image_url: hostedImage.url,
          image_height: hostedImage.height,
          image_width: hostedImage.width,
          image_url: hostedImage.url,
        })
      })
      .catch((error) => {
        this.setState({
          error: true,
          isUploading: false,
        })
      })
  }

  protected handleOnEnter(e) {
    if (e.key === 'Enter') {
      this.fetchArticle()
    }
  }

  protected validUrl(): boolean {
    return Validation.validateForgivingUrl(this.props.data.url).valid
  }

  protected validArticle(): boolean {
    return Validation.validateUrl(this.props.data.url).valid &&
           this.props.data.title &&
           this.props.data.title.length > 0
  }

  protected saveArticle() {
    if (isInCutoffWindow(this.state.nextIssue)) {
      this.props.propertiesChanged({
        boost_level: NORMAL_BOOSTED_BOOST_LEVEL,
        created: this.state.nextIssue.cutoff,
      })
    }

    const articleType = ArticleType[this.state.type]
    if (articleType && articleType.name === ArticleType.reserved.name) {
      this.props.propertiesChanged({
        is_reserved: true,
      })
    }

    this.setState({
      error: false,
      isSaving: true,
    }, () => {
      this.saveRecord(null).then(() => {
        this.setState(initialAddArticleState, () => this.clearProps())
        if (articleType) {
          this.props.push(`${DashboardMenuOption.content}/${articleType.route}`)
        } else {
          this.props.push(DashboardMenuOption.content)
        }
      }).catch(() => {
        this.props.openModal(ArticleAlertModal.key, ArticleAction.SAVE)
        this.setState({
          error: true,
          isSaving: false,
        })
      })
    })
  }

  protected clearProps() {
    this.resetArticleFields()
    this.propertiesChanged({
      url: '',
    })
  }

  protected resetArticleFields() {
    this.propertiesChanged({
      boost_level: null,
      created: null,
      content_category: '',
      content_type: '',
      description: '',
      image: null,
      image_url: null,
      is_reserved: null,
      publisher: '',
      retrieved_url: '',
      site_name: '',
      title: '',
    })
  }

  protected fetchArticle() {
    this.resetArticleFields()
    this.setState({
      isFetching: true,
    })
    const url: string = `${AjaxWrapper.getServerUrl()}/article/${this.state.communityId}/find`
    AjaxWrapper.ajax(url, HttpMethod.POST, {
                  application: 'dashboard',
                  identifier: this.state.selectedCommunity,
                  url: this.props.data.url,
                })
                .then((newData: Post) => {
                  this.props.propertiesChanged({
                    content_type: 'Content',
                    description: newData.description,
                    image_url: newData.image,
                    override_url: true,
                    publisher: newData.publisher,
                    retrieved_url: newData.url,
                    site_name: newData.publisher,
                    title: newData.title,
                  })
                  this.setState({
                    isFetched: true,
                    isFetching: false,
                  })
                })
                .catch((error) => {
                  this.props.openModal(ArticleAlertModal.key, ArticleAction.FETCH)
                  this.setState({
                    error: true,
                    isFetched: true,
                    isFetching: false,
                  })
                })
  }

  protected urlChanged(value: string) {
    this.propertiesChanged({
      url: value.trim(),
    })
  }

  private articleDescription(): string {
    if (this.props.data.description && this.props.data.description.length > 200) {
      return `${this.props.data.description.substr(0, 200)} ...`
    } else {
      return this.props.data.description
    }
  }

  private setAnInterval = (end) => {
    return new Promise((resolve, reject) => {
    this._interval = setInterval(() => {
      const now = new Date()
      const timeToEnd = differenceInSeconds(new Date(end), now)
      if (timeToEnd < 0) {
        clearInterval(this._interval)
        this.setState({ currentTime: now })
      }
    }, 1000)
    })
  }

  private setIntervals = () => {
    const {nextIssue} = this.state
    if (nextIssue && isBeforeCutoff(nextIssue)) {
      this.setAnInterval(nextIssue.cutoff).then(() => this.setAnInterval(nextIssue.production_cutoff))
    }
    if (nextIssue && isInCutoffWindow(nextIssue)) {
      this.setAnInterval(nextIssue.production_cutoff)
    }
  }

  private getSections = (community) => {
    new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      const sections = response[0] || []
      this.setState({sections: this.normalizeSections(sections)})
    })
}

  private normalizeSections = (sections: SourceSection[]): DropdownOption[] => {
    const normalizedSections = sections.map((s) => ({
      description: s.name,
      key: s.id,
      value: s.category_type,
    }))
    if (sections.length) {
      normalizedSections.unshift({description: 'No section', key: 0, value: null})
    }
    return normalizedSections
  }

  private onDropdownChange = (e) => {
    this.setState({selectedSection: e.selected})
    this.props.propertiesChanged({
      content_category: e.selected.value,
    })
  }

  private redirectToUpcomingArticles = () => {
    this.props.push(DashboardMenuOption.content)
  }

}
export const ContentPoolAddArticle = GenericRedux.registerNewComponentWithModals<Post>(
  ContentPoolAddArticleComponent, 'rasaCommunityArticle',
  [AddEditArticleModal.key, ArticleAlertModal.key, GeneratedSummaryModalComponent.key,
  ScheduleArticleModal.key, SectionSelectModal.key])
