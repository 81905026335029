import { DropdownComponent, DropdownOption, MultiSelectDropdown, OnChangeEvent } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import * as Columns from 'components/grid/columns'
import { HeaderComponent } from 'components/header/component'
import { SearchIcon } from 'components/icons/searchicon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import { Dataset } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import { PagingSource, RasaDataGrid } from 'generic/rasaDataGrid'
import React, { Component } from 'react'
import { Roles } from 'shared/constants'
import { isInCutoffWindow } from 'shared/data-layer/issues'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_COMMUNITY_PARTNER_CODES, NORMAL_BOOSTED_BOOST_LEVEL } from 'shared_server_client/constants'
import { BillingPlanDetailCode } from 'shared_server_client/types/billing_plan'
import * as Constants from './constants'
import {
  ArticleLink,
  BoostTypeCell,
  DateTimeCell,
  ImageCell,
  SourceCell,
  TitleCell,
} from './kendocells'
import './styles.css'
import { ArticleSections, DisplayArticle } from './types'
import { imageIsTooSmall } from './utility/utils'

type Article = any
type Issue = any

type ArticleHistoryProps = GenericRedux.DatasetContainerComponentProps<Article>

interface ArticleHistoryState {
  activeCommunity: any,
  articles: Article[],
  articlesToDisplay: DisplayArticle[],
  emailTemplate: string,
  hasReserveContentAccess: boolean,
  issuesLoading: boolean,
  issues: Issue[],
  search: string,
  sections: any[],
  segments: any[],
  selectedFilterOption: DropdownOption,
  selectedIssue: Issue,
  selectedSection: string,
  selectedSections: string[],
  selectedSegments: string[],
  selectedSources: string[],
  sources: any[],
  panelWidth: number,
  updatedArticles: any[],
}

class ArticleHistoryComponent extends Component<ArticleHistoryProps, ArticleHistoryState> {
  public static contextType = RasaContext
  private columns: Columns.SizableColumns
  private historyColumns: Columns.SizableColumns
  private sharedStore: SharedStore

  constructor(props: ArticleHistoryProps) {
    super(props)
    this.state = {
      articles: [],
      articlesToDisplay: [],
      emailTemplate: '',
      hasReserveContentAccess: false,
      issuesLoading: true,
      issues: [],
      search: '',
      sections: [],
      segments: [],
      selectedFilterOption: Constants.AllFilters[0],
      selectedIssue: {key: 'Select a Newsletter', value: -1},
      selectedSection: ArticleSections.active,
      selectedSections: [],
      selectedSegments: [],
      selectedSources: [],
      sources: [],
      panelWidth: 0,
      activeCommunity: {},
      updatedArticles: [],
    }
    this.moveToUpcomingArticle = this.moveToUpcomingArticle.bind(this)
    this.moveToReservedPool = this.moveToReservedPool.bind(this)
    this.moveToContentPool = this.moveToContentPool.bind(this)
  }

  public render() {
    const gridStyles = {
      width: this.state.panelWidth + Constants.GRID_MARGIN_WIDTH,
    }
    return (
      <div className="article-history">
        <HeaderComponent
          title={'ARTICLES'}
          subTitle={'History'}
          description={['Articles that have previously qualified for one of your newsletters.']}
        />
        {this.state.issuesLoading
        ? <div className="main-loading"><Loading size="64"/></div>
        : <div>
          <div className="search-field">
          <SearchIcon/>
          <input type="text"
            placeholder="Search by title, description, source_name or url"
            onChange={(e) => this.setSearch(e.target.value)}/>
        </div>
        <div className="filter-section">
          <div className="filter-icon-container">
            <i className="fa fa-filter" aria-hidden="true" />
          </div>
          <DropdownComponent data={Constants.AllFilters.filter((item) =>
            // Need to check for the length of section > 1 because we always have a No section option
            (this.state.sections.length > 1 || item.key !== Constants.FilterOption.SECTION) &&
            (this.state.sources.length > 0 || item.key !== Constants.FilterOption.SOURCE) &&
            // Need to check for the length of segments > 2 because we always have All Segments & No Code options
            (this.state.segments.length > 2 || item.key !== Constants.FilterOption.SEGMENT) &&
            (item.key !== Constants.FilterOption.AI_RECOMMEND_DEACTIVATION) &&
            (item.key !== Constants.FilterOption.AI_GENERATED_IMAGE)
          )}
          selected={this.state.selectedFilterOption.key}
          onChange={ (e: OnChangeEvent) =>
            this.setState({
              selectedFilterOption: e.selected,
              selectedSections: [],
              selectedSources: [],
            })
          }/>
          {this.state.selectedFilterOption.key === Constants.FilterOption.SOURCE && this.state.sources.length > 0 &&
            <MultiSelectDropdown
              placeholder="All Sources"
              className={'multi-select-dropdown'}
              defaultValue={this.state.sources ? this.state.sources[0].id : null}
              options={this.state.sources.map((source) => {
                return {
                  label: source.name,
                  value: source.name,
                }
              })}
              onSelect={(e) => this.onSourcesFilterChange(e)}
            />}
            {this.state.selectedFilterOption.key === Constants.FilterOption.SEGMENT && this.state.segments.length > 2 &&
                <MultiSelectDropdown
                  placeholder="All Segments"
                  className={'multi-select-dropdown'}
                  defaultValue={this.state.segments ? this.state.segments[0].code : null}
                  options={this.state.segments.map((segment) => {
                    return {
                      label: segment.code,
                      value: segment.code,
                    }
                  })}
                  onSelect={(e) => this.onSegmentsFilterChange(e)}
                />}
            {this.state.selectedFilterOption.key === Constants.FilterOption.SECTION
              && this.state.sections.length > 1 &&
              <MultiSelectDropdown
                placeholder="All Sections"
                className={'multi-select-dropdown'}
                defaultValue={this.state.sections ? this.state.sections[0].code : null}
                options={this.state.sections.map((section) => {
                  return {
                    label: section.name,
                    value: section.name,
                  }
                })}
                onSelect={(e) => this.onSectionsFilterChange(e)}
              />}

              {/* Load dropdown */}
              {this.state.issues.length
              ? <div className="issue-selector">
                  <div className="issues-dropdown">
                    {this.state.issues.length &&
                    <DropdownComponent
                      data={this.state.issues}
                      selected={this.state.selectedIssue.key}
                      onChange={this.issueChanged}/>}
                  </div>
                </div>
              : null
              }
            </div>
          {/* Load grid */}
          {this.state.selectedIssue && this.state.selectedIssue.value > 0 ?
          <RasaDataGrid
            // Rasa Properties for entity, datasetName etc
            entityName="communityArticle"
            datasetName="contentPool"
            datasetParams={[{param: 'issueId', value: this.state.selectedIssue.value}]}
            editDefaultState={true}
            clearDataOnRefresh={true}
            // events
            onDataReceived={this.dataReceived}
            sortable={true}
            pageable={true}
            pageSize={100}
            pagingSource={PagingSource.local}
            gridFilter={this.filterData}
            gridTransform={this.transformData}
            data={[]}>
            {this.columns.buildColumns(this.state.panelWidth)}
          </RasaDataGrid>
          :
          <RasaDataGrid
              // Rasa Properties for entity, datasetName etc
              entityName="communityArticle"
              datasetName="communityArticleHistory"
              clearDataOnRefresh={true}
              editDefaultState={true}
              // events
              onDataReceived={this.dataReceived}
              sortable={true}
              pageable={true}
              gridStyles={gridStyles}
              pageSize={100}
              pagingSource={PagingSource.local}
              data={this.props.container.data}
              gridFilter={this.filterData}
              gridTransform={this.transformData}>
            {this.historyColumns.buildColumns(this.state.panelWidth)}
          </RasaDataGrid>
          }
          </div>
        }
      </div>
    )
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.sharedStore = SharedStore.instance(this.context)
    this.handleResize()
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
      this.sharedStore.getValue(SharedKeys.emailTemplate),
    ])
    .then(([activeCommunity, role, emailTemplate]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({activeCommunity})
      return Promise.all([
        this.getIssueDataForFilter(activeCommunity.communityId),
        this.getSections(activeCommunity.communityId),
        this.getSources(activeCommunity.communityId),
        this.getSegments(activeCommunity.communityId)
      ])
      .then(([issueDataForFilter, sectionsRes, sourcesRes, segmentRes]) => {
        this.columns = new Columns.SizableColumns(this.getColumns())
        this.historyColumns = new Columns.SizableColumns(this.getColumns(true))

        if (issueDataForFilter.length > 1) {
          this.setState({selectedIssue: issueDataForFilter[1]})
        }
        this.setState({
          issuesLoading: false,
          hasReserveContentAccess: avlFeatures.indexOf(BillingPlanDetailCode.RESERVE_CONTENT) > -1
          || role === Roles.super_admin,
          issues: issueDataForFilter,
          emailTemplate: emailTemplate.toString(),
        })
      })
    })
  }

  private getSections = (community) => {
    new Dataset()
    .loadCommunityDataset('sections', community)
    .then((response) => {
      const  avlSections = [...response[0], {category_type_id: 0, name: Constants.NO_SECTION}]
      return this.setState({sections: avlSections || []})
    })
  }

  private getSegments = (communityId) => {
    return new Dataset()
    .loadCommunityDataset('communityPartnerSegmentCode', communityId, [{param: 'includeDefaultOptions', value: 1}])
    .then((response) => {
      if (!response) {
        return
      }
      this.setState({
        segments: response[0] || [],
      })
    })
  }

  private getSources = (communityId) => {
    return new Dataset()
    .loadCommunityDataset('communitySources', communityId)
    .then((response) => {
     this.setState({sources: response[0] || []})
    })
  }

  private setSearch = (s: string) => {
    this.setState({
      search: s,
    })
  }

  private onSourcesFilterChange(selections: any[]) {
    const sourcesTo = selections.map((s) => s.value)
    this.setState({
      selectedSources: sourcesTo,
    })
  }

  private onSegmentsFilterChange(selections: any[]) {
    const segmentsTo = selections.map((s) => s.value)
    this.setState({
      selectedSegments: segmentsTo,
    })
  }

  private onSectionsFilterChange(selections: any[]) {
    const sectionsTo = selections.map((s) => s.value)
    this.setState({
      selectedSections: sectionsTo,
    })
  }

  private dataReceived = (data) => {
    if (data.data) {
      // Force throwing away any upcoming articles that are after our cutoff window.
      // We don't want them at all.
      const articlesToDisplay = this.getArticlesToDisplay(data.data)
      this.setState({
        articles: articlesToDisplay,
        articlesToDisplay,
        updatedArticles: articlesToDisplay,
      })
    }
  }

  private getArticlesToDisplay = (allArticles: DisplayArticle[]): DisplayArticle[] => {
    return allArticles
      .reduce((displayArticles: DisplayArticle[], current: DisplayArticle) => {
        const existing = displayArticles.findIndex((a: DisplayArticle) => this.matches(a, current))
        if (existing === -1) {
          return displayArticles .concat([{...current,
            duplicates: [],
          }])
        } else {
          displayArticles[existing].duplicates.push(current)
          return displayArticles
        }
      }, [])
  }

  private matches = (article: DisplayArticle, current: DisplayArticle): boolean => {
    return article.title === current.title &&
    article.description === current.description &&
    article.is_active === current.is_active &&
    article.source === current.source &&
    article.section_name === current.section_name &&
    article.url.split('#')[0] === current.url.split('#')[0]
  }

  private isArticleMatchingSearch = (a: DisplayArticle): boolean => {
    if (this.state.selectedFilterOption.key === Constants.FilterOption.NO_IMAGE
        && a.hosted_image_url) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.IMAGE_WARNING && (!a.hosted_image_url
      || !imageIsTooSmall(a.image_height, a.image_width, this.state.emailTemplate))) {
      return false
    } else if (this.state.selectedFilterOption.key === Constants.FilterOption.TOGGLE_ON_OFF && !a.last_updated_community_person_id) {
      return false
    } else if (this.state.selectedSources.length > 0 && !this.state.selectedSources.includes(a.source_name)) {
      return false
    } else if (this.state.selectedSections.length > 0) {
      // note that selectedSections is an array
      // combination could be: 1 section or multiple sections
      // no section and one of the section or multiple sections
      const selectedSections = [...this.state.selectedSections]
      const noSectionIndex = selectedSections.indexOf(Constants.NO_SECTION)
      if (noSectionIndex > -1) {
        selectedSections[noSectionIndex] = null
      }
      if (!selectedSections.includes(a.section_name)) {
        return false
      }
    } else if (this.state.selectedSegments.length > 0) {
      // note that selectedSegments is an array
      // combination could be: 1 segment or multiple segments
      // no segment and one of the segment or multiple segments
      const selectedSegments = [...this.state.selectedSegments]
      const noSegementIndex = selectedSegments.indexOf(DEFAULT_COMMUNITY_PARTNER_CODES.NO_CODE)
      const allSegementIndex = selectedSegments.indexOf(DEFAULT_COMMUNITY_PARTNER_CODES.ALL)
      if (noSegementIndex > -1) {
        selectedSegments[noSegementIndex] = null
      }
      if (!selectedSegments.includes(a.community_partner_code) && allSegementIndex === -1) {
        return false
      }
    }
    if (this.state.search.length >= 3) {
      const searchTerm = this.state.search.toLowerCase()
      if (
        (a.title || '').toLowerCase().includes(searchTerm) ||
        (a.description || '').toLowerCase().includes(searchTerm) ||
        (a.url || '').toLowerCase().includes(searchTerm) ||
        (a.source_name || '').toLowerCase().includes(searchTerm)) {
          return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  private transformData = (a: DisplayArticle): DisplayArticle => {
    return this.state.updatedArticles.filter((x) => x.id === a.id)[0]
  }
  private filterData = (a: DisplayArticle): boolean => {
    return this.isArticleMatchingSearch(a) && this.state.articles.filter((x) => x.id === a.id).length > 0
  }

  private ArticleEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props}
      canSave={true}
      saveLabel="Add to Upcoming"
      onSave={this.moveToUpcomingArticle}
      canMarkReserved={this.state.hasReserveContentAccess}/>
  }

  private BoostTypeCell = (props: EditCellProps) => {
    return <BoostTypeCell {...props} />
  }

  private getIssueDataForFilter = (communityId) => {
    return new Dataset().loadCommunityDataset('communityIssues', communityId, null, 10)
      .then((issues) => {
        const allIssuesData = issues[0]
          .filter((i) => i.content_pool_size > 0)
          .map((i) => {
            const d: Date = new Date(i.send_at_in_timezone)
            const dateWithoutOffset = d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
            const key = format(new Date(dateWithoutOffset), 'iiii, MMM d H:mm')
            return {
              description: key,
              key,
              value: i.id,
            }
          })
        const issueDataForFilter = allIssuesData.length ? [
          {
            key: 'Select a Newsletter',
            divider: true,
            value: '-1',
          },
          ...allIssuesData,
          Constants.ShowAllDropdownOption,
        ] : []

        return issueDataForFilter
    })
  }
  private moveToUpcomingArticle(item: any) {
    return this.moveToContentPool(item)
  }
  private moveToReservedPool(props: EditCellProps) {
    return this.moveToContentPool(props.dataItem, true)
  }
  private moveToContentPool(item: any, isReserved: boolean = false) {
    return this.context.entityMetadata.getEntityObject('communityArticle').then((entity) => {
      entity.setRecordId(this.state.activeCommunity.communityId, null);
      entity.data.community_article_id = item.id
      if (isInCutoffWindow(this.state.activeCommunity.nextIssue)) {
        entity.data.boost_level = NORMAL_BOOSTED_BOOST_LEVEL
        entity.data.created = this.state.activeCommunity.nextIssue.cutoff
      }
      entity.data.is_reserved = isReserved ? isReserved : null
      return entity.save()
        .then(() => {
          this.context.store.dispatch(Flash.showFlashMessage(isReserved ? Constants.RESERVED_ARTICLE_MOVED
            : Constants.UPCOMING_ARTICLE_MOVED))
        })
        .catch(() => {
          this.context.store.dispatch(Flash.showFlashError(Constants.UPCOMING_ARTICLE_MOVED_ERROR))
        })
    })
  }
  private issueChanged = (e: any) => {
    this.setState({selectedIssue: e.selected})
  }

  private handleResize = () => {
    this.setState({
      panelWidth: window.innerWidth - Columns.LEFT_PANEL_DEFAULT_WIDTH,
    })
  }

  private LinkableTitleCell = (props: any) => {
    return <TitleCell {...props} onClick={(e) => this.openLink(e.url)}/>
  }

  private openLink = (url: string) => {
    window.open(url, '_blank')
  }

  private getColumns = (isHistory: boolean = false): Columns.SizableColumn[] => {
    const gridColumns = [
      {
        cell: ImageCell,
        field: 'image_url',
        minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
        sortable: false,
        title: 'Image',
        width: 150,
      },
      {
        cell: this.LinkableTitleCell,
        field: 'title',
        minWidth: 400,
        title: 'Title',
      },
      {
        cell: ArticleLink,
        sortable: false,
        title: '',
        width: 35,
      },
      {
        cell: SourceCell,
        field: 'site_name',
        title: 'Source',
        width: 150,
      },
      {
        cell: DateTimeCell,
        field: 'created',
        minDisplayWidth: Columns.TABLET_DISPLAY_WIDTH,
        title: 'Published',
        width: 150,
      },
      {
        field: 'section_name',
        title: 'Section',
        width: 100,
        hidden: this.state.sections.length <= 1,
      },
      {
        cell: this.ArticleEditCell,
        field: '',
        title: '',
        width: 50,
      },
    ]
    if (!isHistory) {
      gridColumns.splice(gridColumns.length - 2, 0, {
        cell: this.BoostTypeCell,
        field: '',
        title: '',
        width: 100,
      })
    }
    return gridColumns
  }
}

export const ContentPoolArticleHistory = GenericRedux.registerNewDatasetContainerComponent<Article>(
  ArticleHistoryComponent, 'article_history')
